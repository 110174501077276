import { Fragment } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Menu, Transition, Disclosure } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const navigation = [
  { name: 'Soluciones', hash: '/#Soluciones' },
  { name: 'Medios de pago', hash: '/#MediosDePago' },
];

const disclosurePanel = [
  { name: 'Soluciones', hash: '/#Soluciones' },
  { name: 'Medios de pago', hash: '/#MediosDePago' },
  { name: 'Banking', hash: '/#Banking' },
  { name: 'Pagar con Qr', hash: '/#QrSection' },
  { name: 'Integración vía API', hash: '/#ApiSection' },
  { name: 'Contactanos', hash: '/#Form' },
];

import Logo from 'Assets/Img/Logo.svg';
const Navbar = () => (
  <>
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 md:px-7 ">
            <div className="relative flex justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-settle-primary-500">
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/">
                    <img className="h-8 w-auto sm:h-10" src={Logo} />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      className="font-medium text-gray-400 hover:text-gray-900 inline-flex items-center px-.5 pt-1 whitespace-nowrap"
                      to={item.hash}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Menu
                    as="div"
                    className="relative font-medium text-gray-400 hover:text-gray-900 inline-flex items-center pt-1"
                  >
                    <div className="mx-4">
                      <Menu.Button className="hover:text-gray-900 inline-flex items-center pt-1 bg-white font-medium text-gray-400">
                        Funciones
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 top-[3rem] w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#Banking"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Banking
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#QrSection"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Pagar con Qr
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#ApiSection"
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                )}
                              >
                                Integración vía API
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <Link
                    className="font-medium text-settle-primary-500 hover:text-gray-900 inline-flex items-center px-1 pt-1"
                    to="/#Form"
                  >
                    Contactanos
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="sm:hidden" id="mobile-menu">
              <div className="pt-2 pb-4 space-y-1">
                {disclosurePanel.map((item) => (
                  <Link
                    key={item.name}
                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                    to={item.hash}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  </>
);
export { Navbar };
