import { ReactComponent as Lock } from 'Assets/Icons/Lock.svg';
import { ReactComponent as MoneyCashBillSend } from 'Assets/Icons/MoneyCashBillSend.svg';
import { ReactComponent as MoneyWallet } from 'Assets/Icons/MoneyWallet.svg';
import { ReactComponent as User } from 'Assets/Icons/User.svg';

const features = [
  {
    img: <Lock />,
    name: 'Protección avanzada contra fraudes',
    description: 'Personalice con filtros y herramientas basados ​​en reglas que se adapten a su modelo de negocio.',
  },
  {
    img: <MoneyCashBillSend />,
    name: 'Administración de información del cliente',
    description: 'Permita que los clientes que regresan paguen fácilmente sin volver a ingresar los detalles de pago.',
  },
  {
    img: <MoneyWallet />,
    name: 'Múltiples métodos de pago',
    description: 'Facilite a los clientes el pago de la forma que prefieran.',
  },
  {
    img: <User />,
    name: 'Pagos recurrentes',
    description: 'Acepte y envíe pagos mensuales recurrentes.',
  },
];

const MainFeatures = () => (
  <div id="Soluciones" className="bg-gray-50 bg-opacity-50">
    <div className="flex flex-col max-w-7xl min-h-[700px] mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 justify-center">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold  text-gray-900">
          Acelerá tu negocio con nuestras soluciones de pagos y cobranzas digitales.
        </h2>
      </div>
      <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
        {features.map((feature) => (
          <div key={feature.name} className="relative">
            <div className="m-9">{feature.img}</div>
            <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
            <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
);
export { MainFeatures };
