/* eslint-disable */
import './styles.css';
import { DOCK_LINK_TITLES, docLinks } from "../../utils/constants/DocInfo.constants";
import Layout from "Components/Layout";
import { Link } from 'react-router-dom';
import DocInfo from './components/DocInfo';
import { useState } from 'react';

const ApiDocs = () => {
    const [type, setType] = useState(DOCK_LINK_TITLES.PARTNER);
    const [subtype, setSubtype] = useState();

    const handleClick = (newType, newSubtype = null) => {
        setType(newType);
        setSubtype(newSubtype);
    }
    return (
        <>
            <Layout>
                <div className="container">
                    <div className='card-container drop-shadow'>
                        <h1 className="text-5xl tracking-tight font-extrabold text-gray-900 text-center">
                            <span className="">Api Docs</span>
                        </h1>
                        {docLinks.map(blockLink => <div className='text-container'>
                            <div>
                                <Link onClick={() => handleClick(blockLink.type)} className="font-medium text-settle-primary-500 hover:text-gray-900 inline-flex items-center px-1 pt-1">{blockLink.title}</Link>
                                {blockLink.links.map((link) => (
                                    <li key={link} className="px-6 py-2 underline">
                                        <Link onClick={() => handleClick(blockLink.type, link.id)} className='text-settle-primary-500 hover:text-gray-900'>{link.name}</Link>
                                    </li>
                                ))}
                            </div>
                        </div>)}
                    </div>
                    {type && <div className='docinfo'>
                        <DocInfo type={type} subtype={subtype} />
                    </div>}
                </div>
            </Layout>
        </>
    )
};

export default ApiDocs;
