/* eslint-disable */
import GreyCard from 'Components/shared/GreyCard'
import { dockLinksIds } from 'utils/constants/DocInfo.constants'
import { Provinces } from 'utils/constants/provinces'
const Banking = () => {
    return (
        <div>
            <h2 className="text-3xl font-bold  text-gray-900 mb-4">
                Banking
            </h2>
            <p className="mb-2">The banking API basically provides the client with all the resources to create users, triggers KYC process, create bank accounts, execute transfers, and issue debit cards (coming soon).
                All products form banking require <i className='font-bold'>partnerKey</i> to access and the product to have been previously activated for the partner, the activation is taken care of in the registration process.</p>
            <div className="px-6">
                <li id={dockLinksIds.Banking_User} className="py-4 font-bold mb-2 underline">User</li>
                <div className="px-6 mb-4">
                    <p className="px-4 mb-2">- POST /banking/ar/users</p>
                    <p className="px-4 mb-2">Endpoint to create a user in ArPay and begin with the KYC process. Notice that the KYC status will be shared via webhook.
                        Given that a KYC webhook notified the client about a user’s onboarding status a it is REJECTED, the POST /banking/ar/users and POST /banking/ar/users/ar/{'{coreUserId}'}/files needs to be requested one more time with the “new” attempt information.
                    </p>
                    <p className="mb-2 underline">validations:</p>
                    <li className='px-2'>A maximum of 3 attempts to success with a KYC process will be allowed per user.</li>
                    <li className='px-2'>The KYC process will be triggered as soon as the POST /banking/ar/users and the POST/banking/ar/users/ar/{'{coreUserId}'}/files are consumed for a given user</li>
                    <li className='px-2'>In case de civilStatus = M, data regarding the partner will be collected</li>
                    <li className='px-2'>PEP object: required if flagged as TRUE</li>
                    <li className='px-2'>FATCA object: required if flagged as TRUE</li>
                    <li className='px-2'>OCDE object: required if flagged as TRUE</li>

                    <p className="mb-2 underline">Enums:</p>
                    <li className='px-2'><i className='font-bold'>gender:</i> M, F</li>
                    <li className='px-2'><i className='font-bold'>identificationType:</i> dni, passport</li>
                    <li className='px-2'><i className='font-bold'>taxIdType:</i> cuil</li>
                    <li className='px-2'><i className='font-bold'>nationality:</i> alpha-2 code ISO3166</li>
                    <li className='px-2'><i className='font-bold'>civilStatus:</i> M, S, W, D, T</li>
                    <li className='px-2'><i className='font-bold'>country:</i> AR</li>
                    <li className='px-2'><i className='font-bold'>province:</i>
                        {Provinces.map(province => <p><small className='px-4'>- {province}</small></p>)}
                    </li>

                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partnerUserId": partners identification for user,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"name": user’s name,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"surname": user’s lastname,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"identificationType": user’s identification type,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"identificationValue": user’s identification value,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"birthdate": user’s birth date format ISO8601,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"email": user’s email,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"taxIdType": user’s tax id type,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"taxIdValue": user’s tax id value,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"nationality": user’s nationaity,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"legalAddress": {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>"streetName": address street,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"streetNumber": address number,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"zipCode": address zip code,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"city": city,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"province": province,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"country": address country</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"gender": user’s gender,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"phone": user’s phone,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"civilStatus": user’s civil status(only if is M, complete partnerDetails),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partnerDetails": {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>"name": partner name,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"surname": partner lastname,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"taxIdType": partner tax id type,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"taxIdValue": tax id value</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"politicallyExposed": user’s condition(only if true send politicallyExposedReason) ,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"politicallyExposedReason": reason of politically exposed,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"foreignTaxResident": user’s condition(only if true send foreignTaxResidencyDetails),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"foreignTaxResidencyDetails": {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>"country": foreign residency country,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"typeNif": nif type,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"nifNumber": nif numbre,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"foreignAddress":foreign residency address,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"foreignCity": foreign residency city</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fatcaResident": user’s condition(only if true send fatcaResidentDetails),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fatcaResidentDetails": {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>"typeNif": nif type,</i></p>
                            <p className="px-4 mx-4 mb-2"><i>"nifNumber": nif numbre</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partnerUserId": "user61fd6d",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"coreUserId": "68ebfsdfs5s6be3800a67185b5"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>


                    <p className="px-4 mb-2 mt-2">- POST /banking/ar/users/ar/{'{coreUserId}'}/files</p>
                    <p className="px-4 mb-2">Endpoint to submit the images regarding a user’s KYC. It is required to submit the thre files (id front, id back, and selfie) to finish the onboarding data submission process. Maximum size allowed: 8MB.</p>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partnerUserId": partners user identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"photoIdFront": img string in base64 of id’s front,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"photoIdBack": img string in base64 of id’s back,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"photoSelfie": img string in base64 of users selfie</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mx-4 mb-2"><i>200</i></p>
                    </GreyCard>
                </div>

                <li id={dockLinksIds.Banking_Account} className="py-4 font-bold mb-2 underline">Account</li>
                <div className="px-6 mb-4"><p>Once a user has been created and the KYC process successfully passed (notified via webhook), the client will be able to request the API to create an account (CVU and alias) for a given user.</p>
                    <p className='mt-2 underline'><b>Restriction:</b> only allowed one account per user</p>
                    <p className="px-4 mb-2">- GET /banking/ar/accounts</p>
                    <p className="px-4 mb-2">Endpoint to retrieve a user’s account</p>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identificaton,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"params": accounts info to be returned(cvu, alias, balance)</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"id": "dhfhd-b5a4-hdfh-2611-51df61df",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"cvu": "455425265265265",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"alias": "alias.YI9",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"balance": "10",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": "ACTIVE"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2">- POST /banking/ar/accounts</p>
                    <p className="px-4 mb-2">Endpoint to create a user’s account</p>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partnerUserId": partners user identification,</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"id": "acc-fg54db561b616",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"alias": "alias.YI9",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"cvu": "552845412542525455",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": "ACTIVE",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"createdAt": "2023-02-14T21:29:58.398395Z"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>


                    <p className="px-4 mb-2">- PATCH /banking/ar/accounts</p>
                    <p className="px-4 mb-2">Endpoint to modify a given user account’s status</p>
                    <p className="px-4 mb-2 mt-2">Status transitioning:</p>

                    <p className="mb-2 underline">validations:</p>
                    <li className='px-2'>if new status is equal to DISABLED or FROZEN the parameter statusUpdateReason is required</li>
                    <li className='px-2'>if statusUpdateReason is OTHER, statusUpdateComment is required</li>
                    <li className='px-2'>ACTIVE: status for an operative account</li>
                    <li className='px-2'>FROZEN: cash outs not allowed</li>
                    <li className='px-2'>DISABLED: cash in/out not allowed</li>

                    <p className="mb-2 underline">Enums:</p>
                    <li className='px-2'>statusUpdateReason: <i>OTHER</i>, <i>LOST</i>, <i>INTERNAL_REASON</i>, <i>STOLEN</i>, <i>FRAUD</i>, <i>INHIBITION</i>, <i>SEIZURE.</i></li>


                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": new status,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateReason": reason for change,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateComment":only if reason is OTHER</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": "User2055630495@mailinator.com",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": "FROZEN",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateReason": "OTHER",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateComment": "Lorem”</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2">- DELETE /banking/ar/accounts</p>
                    <p className="px-4 mb-2">Endpoint to delete a given user’s account</p>
                    <p className="mb-2 underline">validations:</p>
                    <li className='px-2'>an account will be able to be deleted only if its balance is 0.</li>
                    <li className='px-2'>if statusUpdateReason is <i>OTHER</i>, statusUpdateComment is required</li>
                    <p className="mb-2 mt-2 underline">Enums:</p>
                    <li className='px-2'>statusUpdateReason: <i>OTHER</i>, <i>INTERNAL_REASON</i>, <i>USER REQUEST</i>.</li>
                    <p className="mb-2 mt-2 underline">Conditions:</p>
                    <li className='px-2'>AccountBalance equal 0;</li>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateReason": reason for change,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateComment":only if reason is OTHER</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": "uservnssovo4@mailinator.com",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": "DELETED",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"statusUpdateReason": "USER_REQUEST"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>


                    <p className="px-4 mb-2">- PATCH /banking/ar/accounts/alias</p>
                    <p className="px-4 mb-2">Endpoint to modify the alias for a given account</p>
                    <p className="mb-2 underline">validations:</p>
                    <li className='px-2'>The alias has the following validations: between 6 and 20 characters, can contain letters, numbers, dot('.'), hyphen ('-') or comma (',').</li>
                    <li className='px-2'>Can be changed once a day, and 10 times a year (BCRA threshold)</li>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"alias": new alias,</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>userId:"emaill@mail.com",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>alias:"newAlias"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2">- GET /banking/ar/activities</p>
                    <p className="px-4 mb-2">Endpoint to retrieve the activity for a given user’s account.</p>
                    <p className="px-4 mb-2">At the time of making the request number of pages, and size need to be defined, as well as filter by tx status and date.In case no time window is defined, the last 30 days activity will be returned.</p>

                    <p className="mb-2 underline">validations:</p>
                    <li className='px-2'>Dates inputs will be in ISO 8601 format</li>
                    <p className="mb-2 underline">Enum:</p>
                    <li className='px-2'>status: <i>APPROVED</i>, <i>REJECTED</i>, <i>PENDING</i></li>
                    <li className='px-2'>sortBy: createdAt, amount. Default: createdAt</li>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>""idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"page[number]": page number,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"page[size]": amount of records per page,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"transaction[status]": status filter,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"transaction[dateFrom]": date filter,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"transaction[dateTo]": date filter,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"sortBy": param to sort by (- sign to invert order),</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": "usenvronvor@mailinator.com",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partner": "partneName",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"meta": {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>"pagination": {'{'}</i></p>
                            <div className="px-4 mx-4 mb-2">
                                <p className="px-4 mx-4 mb-2"><i>"current_page": 0,</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"total_pages": 1,</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"page_size": 10</i></p>
                            </div>
                            <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"data": {'['}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>{'{'}</i></p>
                            <div className="px-4 mx-4 mb-2">

                                <p className="px-4 mx-4 mb-2"><i>"id": "fspvspvpfv",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"type": "FiatDebit",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"subType": "BankTransferOutOriginal",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"createdAt": "2023-02-03T19:58:30.877Z",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"amount": "10",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"asset": "ARS",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"destination": {'{'}</i></p>
                                <div className="px-4 mx-4 mb-2">
                                    <p className="px-4 mx-4 mb-2"><i>"holderId": "20525929097",</i></p>
                                    <p className="px-4 mx-4 mb-2"><i>"holderIdType": "CUIL",</i></p>
                                    <p className="px-4 mx-4 mb-2"><i>"address": "000595080959299239",</i></p>
                                    <p className="px-4 mx-4 mb-2"><i>"addressType": "CVU"</i></p>
                                </div>
                                <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"status": "APPROVED",</i></p>
                                <p className="px-4 mx-4 mb-2"><i>"referenceCode": null</i></p>

                            </div>
                            <p className="px-4 mx-4 mb-2"><i>{'},'}</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'],'}</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                </div>


                <li id={dockLinksIds.Banking_Transfer} className="py-4 font-bold mb-2 underline">Transfer</li>
                <div className="px-6 mb-4">
                    <p className="px-4 mb-2">- POST /banking/ar/transfer</p>
                    <p className="px-4 mb-2">Endpoint to make an outbound transfer to another account.</p>
                    <p className="px-4 mb-2">It will be possible to deduct a tax prior executing a transfer by declaring a taxAccount, which will be the taxes collection account (fiatTaxAmount= fiatTotalAmount*(1 - 1/1.006)). If it is not declared, no taxes will be deducted.</p>
                    <p className="px-4 mb-2 mt-5">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": user’s identification,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"idType": type of user identification sent(default email),</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"originAccount": origin cvu,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"destinationAccount": destination cvu,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatAsset": asset,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatTotalAmount": amount,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"referenceCode": reference code</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"userId": "Usersnovdnsov@mailinator.com",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"originAccount": "0000326256290292525233",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"destinationAccount": "00003931078652256325",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatAsset": "ARS",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatTotalAmount": 10,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatNetAmount": "10.00",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatFeeAmount": 0,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"fiatTaxAmount": "0.00",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"referenceCode": "testing",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"createdAt": "2023-02-15T19:12:15.286Z"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                </div>
            </div>
        </div>
    )
}

export default Banking