/* eslint-disable */
import { Footer } from './Footer'
import { Navbar } from './Home/Components/Navbar'

const Layout = ({ children }) => {
    return (
        <>
            <div id="home" className="relative bg-white overflow-hidden">
                <div className="max-w-[2000px] mx-auto">
                    <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">
                        <Navbar />
                    </div>
                </div>
            </div>
            {children}
            <Footer />
        </>

    )
}

export default Layout