import { ReactComponent as LaptopHand } from 'Assets/Img/LaptopHand.svg';

const ApiSection = () => (
  <div id="ApiSection" className="bg-gray-50 bg-opacity-50">
    <div className="flex flex-col md:flex-row items-center max-w-7xl min-h-[700px] mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex ">
      <div className="max-w-xl flex w-full justify-center flex-1">
        <LaptopHand />
      </div>
      <div className="mt-10 max-w-md flex-1">
        <h2 className="text-3xl font-bold text-gray-900 sm:tracking-tight">Integración con API, fácil y segura</h2>
        <p className="mt-5 text-lg text-gray-400">
          Un enfoque de pagos y finanzas que prioriza la tecnología, para que puedas integrar de manera fácil y
          eficiente tus necesidades a nuestros servicios.
        </p>
      </div>
    </div>
  </div>
);
export { ApiSection };
