import { CheckCircleIcon, XIcon, XCircleIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ValidationMessage = ({ onClick, hasError, hasSuccess }) => (
  <>
    {hasSuccess && (
      <div
        className={classNames(
          hasSuccess ? 'opacity-1' : 'opacity-0',
          'rounded-md bg-green-50 p-4 mb-4 transition-opacity ease-in-out'
        )}
      >
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">Tu mensaje fue enviado. Gracias!</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                onClick={onClick}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
    {hasError && (
      <div className="rounded-md bg-red-50 p-4 mb-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-red-800">Connection error couldn&apos;t upload the information</p>
          </div>
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-red-600"
                onClick={onClick}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </>
);

ValidationMessage.propTypes = {
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
};

ValidationMessage.defaultProps = {
  hasError: false,
  hasSuccess: false,
};

export { ValidationMessage };
