import Background from 'Assets/Img/Background.png';
import { Navbar } from 'Components/Home/Components/Navbar';
import { Footer } from '../Footer';
import { Hero } from 'Components/Home/Components/Hero';
import { MainFeatures } from 'Components/Home/Components/MainFeatures';
import { PaymentFeatures } from 'Components/Home/Components/PaymentFeatures';
import { Banking } from 'Components/Home/Components/Banking';
import { QrSection } from 'Components/Home/Components/QrSection';
import { ApiSection } from 'Components/Home/Components/ApiSection';
import { Form } from 'Components/Home/Form/Form';

function Home() {
  return (
    <>
      <div id="home" className="relative bg-white overflow-hidden">
        <div className="max-w-[2000px] mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-3xl lg:w-full lg:pb-28 xl:pb-32">
            <Navbar />
            <main className="mt-10 mx-auto max-w-7xl px-8 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <Hero />
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img className="h-full w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src={Background} alt="" />
        </div>
      </div>
      <MainFeatures />
      <PaymentFeatures />
      <Banking />
      <QrSection />
      <ApiSection />
      <Form />
      <Footer />
    </>
  );
}

export default Home;
