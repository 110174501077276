/* eslint-disable */
export const DOCK_LINK_TITLES = {
    PARTNER: 'PARTNER',
    BANKING: 'BANKING',
    WEBHOOK: 'WEBHOOK'
}
export const dockLinksIds = {
    Partner_Registration: 'Partner-Registration',
    Partner_Auth: 'Partner_Auth',
    Partner_Api_key: 'Partner_Api_key',
    Partner_Webhook: 'Partner_Webhook',

    Banking_User: 'Banking_User',
    Banking_Account: 'Banking_Account',
    Banking_Transfer: 'Banking_Transfer',

    Webhook_Fiat_Debit: 'Webhook_Fiat_Debit',
    Webhook_Fiat_Credit: 'Webhook_Fiat_Credit',
    Webhook_Create_Account_Partner: 'Webhook_Create_Account_Partner',
    Webhook_Onboarding_Update: 'Webhook_Onboarding_Update'
}
export const docLinks = [
    {
        title: 'Partner', type: DOCK_LINK_TITLES.PARTNER, links: [
            { name: 'Registration', id: dockLinksIds.Partner_Registration }, { name: 'Auth', id: dockLinksIds.Partner_Auth }, { name: 'Api-Key (Requires partnerSession)', id: dockLinksIds.Partner_Api_key }, { name: 'Webhook (Requires partnerSession)', id: dockLinksIds.Partner_Webhook }
        ]
    },
    { title: 'Banking', type: DOCK_LINK_TITLES.BANKING, links: [{ name: 'User', id: dockLinksIds.Banking_User }, { name: 'Account', id: dockLinksIds.Banking_Account }, { name: 'Transfer', id: dockLinksIds.Banking_Transfer }] },
    { title: 'Webhook notification', type: DOCK_LINK_TITLES.WEBHOOK, links: [{ name: 'FiatDebit', id: dockLinksIds.Webhook_Fiat_Debit }, { name: 'FiatCredit', id: dockLinksIds.Webhook_Fiat_Credit }, { name: 'CreateAccountPartner', id: dockLinksIds.Webhook_Create_Account_Partner }, { name: 'OnboardingUpdate', id: dockLinksIds.Webhook_Onboarding_Update }] },
];