/* eslint-disable */

import GreyCard from "Components/shared/GreyCard"
import { dockLinksIds } from "utils/constants/DocInfo.constants"

const WebhookNotification = () => {
    return (
        <div>
            <h2 className="text-3xl font-bold  text-gray-900 mb-4">
                Webhook notification
            </h2>
            <p className="mb-2">After any main event requested by the client is executed its result is communicated to the client that requested it. This are the events currently handled:</p>
            <div className="px-6">
                <li id={dockLinksIds.Webhook_Fiat_Debit} className="py-4 font-bold mb-2 underline">FiatDebit</li>
                <div className="px-6 mb-4">

                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>id: '2G5xOqqVDhHdsFSSfs5sVj8iW',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>type: 'FiatDebit',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>subType: 'BankTransferOutOriginal',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>createdAt: '2022-10-13T20:16:56.747Z',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>amount: '14',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>asset: 'ARS',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>referenceCode: 'Pago-1',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>userId: ‘mail@mailinator.com',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>destination: {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>holderId: '20378549919',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>holderIdType: 'CUIL',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>address: '00569822099005390823',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>addressType: 'CVU',</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'}'}</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                </div>

                <li id={dockLinksIds.Webhook_Fiat_Credit} className="py-4 font-bold mb-2 underline">FiatCredit</li>
                <div className="px-6 mb-4">

                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>id: '29ic5TUy9SSsrfFER64fw1m',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>webhookType: 'FiatCredit',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>subType: 'BankTransferInOriginal',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>ccreatedAt: '2022-05-26T21:06:02.989Z',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>amount: '10',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>asset: 'ARS',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>referenceCode: 'Pago1',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>userId: 'mail@gmail.com',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>source: {'{'}</i></p>
                        <div className="px-4 mx-4 mb-2">
                            <p className="px-4 mx-4 mb-2"><i>holderId: '27954564260',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>holderIdType: 'CUIL',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>address: '0569893059592927693979',</i></p>
                            <p className="px-4 mx-4 mb-2"><i>addressType: 'CVU',</i></p>
                        </div>
                        <p className="px-4 mx-4 mb-2"><i>{'}'}</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                </div>

                <li id={dockLinksIds.Webhook_Create_Account_Partner} className="py-4 font-bold mb-2 underline">CreateAccountPartner</li>
                <div className="px-6 mb-4">
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"webhookType":"PartnerAccountCreated",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"createdAt":"2022-08-26T14:25:23.189Z",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"created":true,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"cvu":"000050225950000000123",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"alias":"000052782.alias",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"partner":"partnerName"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                </div>

                <li id={dockLinksIds.Webhook_Onboarding_Update} className="py-4 font-bold mb-2 underline">OnboardingUpdate</li>
                <div className="px-6 mb-4">
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>webhookType: 'OnboardingUpdate',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>createdAt: '2023-01-25T14:45:22.466Z',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>partnerUserId: ‘userid’,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>coreUserId: '63cfddd7f9sdv5s2v91r9evve',</i></p>
                        <p className="px-4 mx-4 mb-2"><i>onboardingStatus: 'Approved',</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                </div>
            </div>
        </div>
    )
}

export default WebhookNotification