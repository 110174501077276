import { CheckIcon } from '@heroicons/react/outline';
import { GlobeAltIcon, LightningBoltIcon, ScaleIcon } from '@heroicons/react/outline';
import { useState, useRef } from 'react';

import { ReactComponent as MoneyAtmCard } from 'Assets/Icons/MoneyAtmCard.svg';
import { ReactComponent as MoneyCashBill } from 'Assets/Icons/MoneyCashBill.svg';
import { ReactComponent as PhoneNotification } from 'Assets/Icons/PhoneNotification.svg';
import { ReactComponent as MoneyBank } from 'Assets/Icons/MoneyBank.svg';

import VisaDebit from 'Assets/Img/VisaDebit.png';
import Maestro from 'Assets/Img/Maestro.png';
import Mastercard from 'Assets/Img/Mastercard.png';
import Cabal from 'Assets/Img/Cabal.png';
import Bcr from 'Assets/Img/Bcr.png';
import Provincia from 'Assets/Img/Provincia.png';
import PagoFacil from 'Assets/Img/PagoFacil.png';
import RapiPago from 'Assets/Img/RapiPago.png';

const features = [
  {
    id: 1,
    img: <MoneyCashBill />,
    name: 'Transferencias bancarias por CBU',
    description: 'Reciba pagos desde cualquier banco del país.',
  },
  {
    id: 2,
    img: <PhoneNotification />,
    name: 'Transferencias bancarias 3.0 y CVU',
    description: 'Transferencias más eficientes, economicas y seguras.',
    bcr: Bcr,
  },
  {
    id: 3,
    img: <MoneyAtmCard />,
    name: 'Tarjetas de débito',
    description: 'Acreditación instántanea.',
    maestro: Maestro,
    mastercard: Mastercard,
    visa: VisaDebit,
    cabal: Cabal,
  },
  {
    id: 4,
    img: <MoneyBank />,
    name: 'Efectivo',
    description: 'Amplia red de puntos de cobranza en efectivo integrados a nuestra plataforma.',
    provincia: Provincia,
    pagoFacil: PagoFacil,
    rapiPago: RapiPago,
  },
];

const PaymentFeatures = () => (
  <div id="MediosDePago" className="bg-white">
    <div className="flex flex-col items-center max-w-7xl min-h-[700px] mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 justify-center">
      <div className="max-w-3xl mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-900">Integración con todos los medios de pago</h2>
      </div>
      <div className="pt-12 bg-white">
        <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-16">
            {features.map((feature, id) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-settle-primary-500 text-white">
                    {feature.img}
                  </div>
                  <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 text-base text-gray-500 mb-4">{feature.description}</dd>
                {feature.id === 3 ? (
                  <div className="flex flex-row w-full mt-4">
                    <img className="h-10 mr-2" src={feature.maestro} />
                    <img className="h-10 mr-2" src={feature.mastercard} />
                    <img className="h-10 mr-2" src={feature.visa} />
                    <img className="h-10 mr-2" src={feature.cabal} />
                  </div>
                ) : feature.id === 2 ? (
                  <img className="h-10" src={feature.bcr} />
                ) : (
                  <div className="flex flex-row w-full">
                    <img className="h-10 mr-2" src={feature.provincia} />
                    <img className="h-10 mr-2" src={feature.pagoFacil} />
                    <img className="h-10 mr-2" src={feature.rapiPago} />
                  </div>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </div>
);
export { PaymentFeatures };
