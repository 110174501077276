function Body() {
  return (
    <>
      <div className="xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
        <div className="max-w-max lg:max-w-7xl mx-auto">
          <div className="relative z-10 mb-8 md:mb-2 md:px-6">
            <div className="text-base max-w-prose lg:max-w-none">
              <h2 className="leading-6 text-settle-primary-500 font-semibold tracking-wide uppercase"> Legales</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Información regulatoria de ARPAY
              </p>
            </div>
          </div>
          <div className="relative">
            <svg
              className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)" />
            </svg>
            <svg
              className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <rect width={404} height={384} fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)" />
            </svg>
            <div className="relative md:bg-white md:p-6">
              <div className="lg:grid lg:grid-cols-1 lg:gap-6">
                <div className="prose prose-lg text-gray-500 lg:max-w-none">
                  <p>
                    <br />
                  </p>
                  <p>T&Eacute;RMINOS DE SERVICIO</p>
                  <p>
                    <br />
                  </p>
                  <p>Actualizados por &uacute;ltimo vez el 10/03/2023</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    FCM PAYMENT SOLUTIONS SAS, (en adelante, &ldquo;Arpay&rdquo;), es una sociedad por acciones
                    simplificada constituida de conformidad a las leyes de la Rep&uacute;blica Argentina, inscripta ante
                    el B.C.R.A. en el &nbsp;&ldquo;Registro de Proveedores de Servicios de Pago que ofrecen cuentas de
                    pago&quot; el d&iacute;a 28/12/2021 bajo el n&uacute;mero 33.745. Arpay provee los servicios, ya sea
                    bajo la marca FCMPS, Arpay, o con un nombre diferente, que se encuentran disponibles en los sitios
                    web y/o aplicaciones o funcionalidades ofrecidas por terceras partes en sus propios sitios y/o
                    aplicaciones para acceder a aquellos (en adelante, la &ldquo;Plataforma&rdquo;).
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>I.- ACERCA DE LOS T&Eacute;RMINOS</p>
                  <p>
                    <br />
                  </p>
                  <p>1.1. Contenido</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Los presentes t&eacute;rminos de servicios, todos sus anexos, documentos, materiales e
                    informaci&oacute;n incorporada a estos como referencia (en adelante, los
                    &ldquo;T&eacute;rminos&rdquo;), constituyen un v&iacute;nculo entre Arpay y cualquier persona que
                    acceda y/o utilice la Plataforma y/o cualquiera de los servicios ofrecidos por Arpay por medio de
                    &eacute;sta (en adelante, el &ldquo;Usuario&rdquo;). Estos T&eacute;rminos describen los derechos y
                    obligaciones de Arpay y del Usuario y establecen las condiciones de uso de la Plataforma y de los
                    servicios.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>1.2. Aceptaci&oacute;n de los T&eacute;rminos</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Al acceder a la Plataforma y/o utilizar los servicios, el Usuario reconoce haber le&iacute;do,
                    entendido y aceptado estos T&eacute;rminos, asume todas las obligaciones sujet&aacute;ndose a todas
                    las disposiciones aqu&iacute; establecidas y se compromete a utilizar la Plataforma de conformidad a
                    las condiciones de uso aqu&iacute; dispuestas.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    En caso de que el Usuario no acepte obligarse por estos T&eacute;rminos, deber&aacute; abstenerse de
                    acceder a la Plataforma y de utilizar los servicios ofrecidos por Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>1.3. Modificaci&oacute;n de los T&eacute;rminos</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay podr&aacute; modificar los T&eacute;rminos en cualquier momento, a su exclusiva
                    discreci&oacute;n sin que fuera necesario remitir ning&uacute;n tipo de notificaci&oacute;n al
                    respecto al Usuario. Si el Usuario contin&uacute;a accediendo y utilizando la plataforma y los
                    servicios luego de que los T&eacute;rminos fueran modificados, se considerar&aacute; que ha aceptado
                    impl&iacute;citamente estas modificaciones. Asimismo, Arpay podr&aacute; en cualquier momento
                    modificar o discontinuar todos o parte de los servicios que brinda a trav&eacute;s de la Plataforma,
                    a su exclusiva discreci&oacute;n.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>II.- EL USUARIO</p>
                  <p>
                    <br />
                  </p>
                  <p>2.1. Requisitos para la utilizaci&oacute;n de la plataforma y los servicios</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay es Sujeto Obligado ante la Unidad de Informaci&oacute;n Financiera, de acuerdo a la
                    Resoluci&oacute;n 76/2019. Por consiguiente, y en cumplimiento con las regulaciones vigentes en
                    materia de Prevenci&oacute;n del Lavado de Activos y Financiamiento del Terrorismo, Arpay se
                    encuentra obligado a recabar cierta informaci&oacute;n y/o documentaci&oacute;n de los Usuarios,
                    comprometi&eacute;ndose a mantenerla actualizada a los fines de dar cumplimiento a procesos de
                    identificaci&oacute;n, verificaci&oacute;n, conocimiento y monitoreo de los mismos y su
                    operatoria.&nbsp;
                  </p>
                  <p>
                    <br />
                    El Usuario podr&aacute; utilizar los servicios &uacute;nicamente si es una persona humana, tiene al
                    menos 18 a&ntilde;os de edad, posee Documento Nacional de Identidad expedido por la Rep&uacute;blica
                    Argentina, cuenta con una Clave &Uacute;nica de Identificaci&oacute;n Laboral o una Clave
                    &Uacute;nica de Identificaci&oacute;n Tributaria,reside permanente en la Rep&uacute;blica Argentina,
                    y cumple con las Pol&iacute;ticas de Aceptaci&oacute;n de Clientes dise&ntilde;adas por Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario no podr&aacute; registrarse en la Plataforma en caso de que se encuentre (a) en listas de
                    sanciones o (b) de nacionalidad o residente en pa&iacute;ses o territorios inclu&iacute;dos en
                    listas de sanciones, o cuyo gobierno sea sujeto de sanciones.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Del mismo modo, no podran registrarse en la Plataforma los Usuarios sobre los que se disponga de
                    informaci&oacute;n que los vincule con actividades il&iacute;citas, especialmente aquellas
                    relacionadas con el narcotr&aacute;fico, el terrorismo, y la proliferaci&oacute;n de armas de
                    destrucci&oacute;n masiva.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Al acceder a la Plataforma y utilizar los servicios, el Usuario declara y garantiza que cumple con
                    los requisitos mencionados en este apartado.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>2.2. Incumplimiento de los requisitos</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay tendr&aacute; el derecho de cancelar, suspender o restringir el acceso a la Plataforma o a los
                    servicios, as&iacute; como a tomar cualquier otra acci&oacute;n que considere adecuada, en el caso
                    de que se advierta que el Usuario no cumpla con los requisitos mencionados en el apartado anterior o
                    que dejara de cumplirlos. Bajo ninguna circunstancia, Arpay ser&aacute; responsable por los
                    da&ntilde;os y perjuicios de cualquier &iacute;ndole que el Usuario o cualquier tercero relacionado
                    de alguna manera con este pudieran sufrir como consecuencia de la cancelaci&oacute;n,
                    suspensi&oacute;n o restricci&oacute;n del acceso a la Plataforma o a los servicios como
                    consecuencia de la falta de cumplimiento con los requisitos enunciados en el apartado anterior.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>2.3. Dispositivos electr&oacute;nicos y participaci&oacute;n de terceros</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Los servicios son prestados por Arpay a trav&eacute;s de Internet por medio de la Plataforma a la
                    cual el Usuario deber&aacute; acceder mediante el uso de dispositivos electr&oacute;nicos de su
                    titularidad e implican la participaci&oacute;n de terceros prestadores de servicios complementarios
                    como, por ejemplo, proveedores de servicios de procesamiento automatizado de datos, y de
                    verificaci&oacute;n de identidad.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>2.4. Verificaci&oacute;n de identidad</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    La utilizaci&oacute;n de los servicios provistos por Arpay a trav&eacute;s de la Plataforma
                    podr&aacute; encontrarse sujeta a un proceso de verificaci&oacute;n de &nbsp;identidad del Usuario
                    por parte de Arpay (o a trav&eacute;s de terceros prestadores de aquellos servicios). Arpay se
                    reserva el derecho de no prestar servicios al Usuario o inhabilitar el acceso a la Plataforma o a
                    los servicios en caso de considerarlo conveniente y/o necesario para el cumplimiento de las leyes y
                    de las regulaciones aplicables, o cuando resulte razonable, a su exclusivo criterio y sin aviso
                    previo.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Es posible que Arpay le solicite al Usuario que env&iacute;e informaci&oacute;n adicional a la
                    indicada en la Plataforma, que proporcione registros documentales y todo tipo de instrumentos que le
                    sean requerido.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    La negativa de parte del Usuario a proveer la informaci&oacute;n adicional solicitada y responder
                    las consultas formuladas por los representantes de Arpay otorgar&aacute; a &eacute;sta la facultad
                    de suspender, restringir o cancelar el acceso del Usuario a la Plataforma y los servicios.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>2.5. Responsabilidad por el acceso y utilizaci&oacute;n de la Plataforma</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario es el &uacute;nico responsable por todas las actividades que lleve a cabo a trav&eacute;s
                    del acceso a la Plataforma y la utilizaci&oacute;n de los servicios, aunque no se encuentre en
                    conocimiento de estas, y acepta mantener indemne a Arpay por cualquier da&ntilde;o y/o reclamo
                    derivado del uso de esta.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>2.6. Cesi&oacute;n de la posici&oacute;n&nbsp;</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    En todos los casos, los servicios provistos por Arpay son personales, &uacute;nicos e
                    intransferibles. El Usuario no podr&aacute; ceder, transferir, delegar a un tercero, gravar, ni
                    disponer en forma alguna de los derechos y obligaciones vinculados o derivados de la
                    utilizaci&oacute;n de la Plataforma, los servicios o estos T&eacute;rminos ni su posici&oacute;n de
                    Usuario.&nbsp;
                  </p>
                  <p>Asimismo, se encuentra prohibido al Usuario revender a terceros los servicios de Arpay.</p>
                  <p>
                    <br />
                  </p>
                  <p>2.7. Utilizaci&oacute;n de la Plataforma y los servicios por cuenta propia</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario solamente podr&aacute; acceder a la Plataforma y utilizar los servicios de Arpay por su
                    propia cuenta y en su propio nombre. A tal efecto, Arpay dispondr&aacute; al Usuario una cuenta de
                    pagos asociados a un Clave Virtual Uniforme (&ldquo;CVU&rdquo;) de su nombre y titularidad (la
                    &ldquo;Cuenta Arpay&rdquo;).
                    <br />
                    <br />
                    Se encuentra prohibido el acceso a la Plataforma y la utilizaci&oacute;n de los servicios en
                    representaci&oacute;n y/o por cuenta de terceros.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Todas las referencias a dinero en la Plataforma se entienden hechas en pesos argentinos, a
                    excepci&oacute;n de aquellas en las que expresamente se indique lo contrario
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>III.- SERVICIO DE GESTI&Oacute;N DE PAGOS</p>
                  <p>
                    <br />
                  </p>
                  <p>3.1. Mandato irrevocable</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Al completar una Solicitud de Procesamiento de Pago, el Usuario otorgar&aacute; un mandato
                    irrevocable a Arpay para (i) pagar por su cuenta y orden una determinada suma de dinero en
                    relaci&oacute;n a la cual se complete la Solicitud de Procesamiento de Pago (los &quot;Fondos&quot;)
                    y/o (ii) cobrar los Fondos por su cuenta y orden, sujeto a estos T&eacute;rminos y Condiciones de
                    Uso. El mandato irrevocable otorgado por el Usuario implica una autorizaci&oacute;n a favor de Arpay
                    para disponer en su nombre de ciertos Fondos de su Cuenta Arpay y transferirlos a la Cuenta Arpay
                    del destinatario designado de acuerdo con sus instrucciones. Asimismo, el mandato irrevocable
                    implica una autorizaci&oacute;n del Usuario a favor de Arpay para recibir, cobrar y acreditar en su
                    Cuenta Arpay los Fondos de acuerdo con sus instrucciones. Arpay liquidar&aacute;, acreditar&aacute;
                    y mantendr&aacute; los Fondos del Usuario en su Cuenta Arpay en las condiciones y plazos
                    establecidos en estos T&eacute;rminos y Condiciones de Uso y/u otras condiciones especiales o
                    particulares que se establezcan para otros productos o servicios de Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.2 Usos de la plataforma</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Una vez registrado, el Usuario podr&aacute; hacer uso de todos los servicios ofrecidos por Arpay a
                    trav&eacute;s de su plataforma. Para tal fin, Arpay podr&aacute; proporcionar un CVU.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.3. Instrucciones del Usuario</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Las instrucciones que el Usuario imparta a Arpay &uacute;nicamente tendr&aacute;n validez en
                    aquellos casos en los que se utilicen los medios espec&iacute;ficamente previstos en la Plataforma a
                    tal efecto. No tendr&aacute;n validez alguna las instrucciones formuladas por el Usuario a Arpay a
                    trav&eacute;s de cualquier otro medio distinto de los que Arpay disponga espec&iacute;ficamente en
                    la Plataforma.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Toda instrucci&oacute;n que se imparta por el Usuario a Arpay por medio de la utilizaci&oacute;n de
                    la Plataforma se considerar&aacute; v&aacute;lida, leg&iacute;tima y aut&eacute;ntica, sin necesidad
                    de que &eacute;sta realice ning&uacute;n otro tipo de confirmaci&oacute;n.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.4. Irrevocabilidad de las instrucciones</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Una vez efectuadas, todas las &oacute;rdenes e instrucciones impartidas por el Usuario a Arpay por
                    medio de la Plataforma ser&aacute;n irrevocables y no podr&aacute; modificarlas, cancelarlas o
                    anularlas en ning&uacute;n momento.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay podr&aacute;, a su exclusivo y absoluto criterio, de manera unilateral y en cualquier
                    circunstancia, revertir, cancelar, modificar y/o ignorar cualquier orden o instrucci&oacute;n
                    impartida por el Usuario por medio de la Plataforma. Esto podr&aacute; ocurrir, sin exclusi&oacute;n
                    de otros motivos no enunciados, cuando: a) Arpay se encuentra obligada a ello por alguna normativa,
                    precepto legal o por orden de una autoridad administrativa o judicial, b) existan errores o fallas
                    t&eacute;cnicas o tecnol&oacute;gicas, c) Arpay detecte que se trata de &oacute;rdenes evidentemente
                    err&oacute;neas, ya sea por su cantidad, monto o cualquier otro factor, d) Arpay detecte un uso
                    abusivo de la Plataforma o incumplimiento de estos T&eacute;rminos, o e) Arpay advierta el
                    involucramiento del Usuario en la comisi&oacute;n de delitos o que este se encuentre vinculado a
                    realizaci&oacute;n de cualquier tipo de actividad ilegal.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay no ser&aacute; responsable por el cumplimiento de las instrucciones remitidas por el Usuario
                    por medio de la Plataforma que fueran consideradas equivocadas, err&oacute;neas o incompletas por
                    parte de aquel. El Usuario acepta y asume la responsabilidad por las p&eacute;rdidas que se
                    originen, especialmente si las instrucciones por &eacute;ste impartidas devinieron duplicadas,
                    fraudulentas, o no autorizadas, as&iacute; como por los eventuales perjuicios que dichas
                    instrucciones pudieran causarle a Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.5. Prueba de las instrucciones</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario acepta que todas las &oacute;rdenes impartidas a Arpay a trav&eacute;s de los medios
                    especialmente habilitados a este efecto en la Plataforma, ser&aacute;n tenidas como prueba de su
                    voluntad de la realizaci&oacute;n de aquellas.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Todas las operaciones efectuadas por el Usuario en la Plataforma se registrar&aacute;n en la fecha y
                    horario en que efectivamente se cursen. En ciertos casos, la fecha u horario de registro de la
                    operaci&oacute;n podr&aacute; ser posterior al momento en que se haya solicitado su
                    ejecuci&oacute;n, dependiendo del tipo de operaci&oacute;n y de tratarse de d&iacute;as
                    h&aacute;biles o inh&aacute;biles.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Asimismo, el registro de la fecha y horario de las operaciones efectuadas por el Usuario
                    podr&aacute;n encontrarse sujetas a condiciones adicionales por parte de cada tercero prestador de
                    servicios (como por ejemplo entidades bancarias y otros prestadores de servicios de transferencias
                    de dinero y/o pago).
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.6. Aceptaci&oacute;n de las instrucciones</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Las instrucciones impartidas por el Usuario a Arpay por medio de la utilizaci&oacute;n de la
                    Plataforma no se considerar&aacute;n perfeccionadas ni generar&aacute;n ning&uacute;n tipo de
                    responsabilidad u obligaci&oacute;n a cargo de esta &uacute;ltima hasta tanto Arpay no las acepte y
                    no se hubieran recibido y se encuentren disponibles la totalidad de las sumas de dinero necesarias
                    para dar cumplimiento con aquellas.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.8. Relaci&oacute;n con terceros&nbsp;</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay resulta completamente ajena a la relaci&oacute;n jur&iacute;dica que pudiera existir entre el
                    Usuario y el tercero al cu&aacute;l se le imparti&oacute; un pago. Arpay no es responsable ni
                    realizar&aacute; ning&uacute;n tipo de verificaci&oacute;n relativa a la existencia, objeto, causa,
                    precio, importe o cualquier otra circunstancia vinculada al acto jur&iacute;dico, operaci&oacute;n o
                    negocio que pudiera haber motivado la instrucci&oacute;n de pago impartida por el Usuario. Arpay no
                    es responsable ni verificar&aacute; en forma alguna la existencia, entidad, calidad,
                    extensi&oacute;n, cantidad, condici&oacute;n, estado, integridad o legitimidad de los bienes y/o
                    servicios que pudieran haber sido ofrecidos, vendidos o comercializados de cualquier manera por el
                    tercero.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay no ofrece ning&uacute;n tipo de garant&iacute;a ni se responsabiliza por la informaci&oacute;n
                    y/o las ofertas publicadas o publicitadas de cualquier forma y por cualquier medio por los terceros.
                    En consecuencia, Arpay no garantiza ni se responsabiliza porque la referida informaci&oacute;n o
                    publicidad sea exacta o veraz, como as&iacute; tampoco que se encuentre libre de errores, omisiones,
                    falsedades o imprecisiones t&eacute;cnicas o de cualquier otra naturaleza.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario comprende y acepta que los servicios, as&iacute; como cualquier otra informaci&oacute;n
                    que obtenga de Arpay, no se encuentran dirigidos, dise&ntilde;ados o en modo alguno orientados a
                    proveer ning&uacute;n tipo de asesoramiento financiero o consejos de inversi&oacute;n o compra de
                    tipo alguno. El Usuario comprende y acepta que es el &uacute;nico responsable por la
                    utilizaci&oacute;n de la Plataforma y los servicios.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.10. Disponibilidad de los fondos</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay, conforme normativa aplicable, mantendr&aacute;, en todo momento, separados e individualizados
                    los fondos de la cuenta del Usuario (en adelante, &ldquo;Cuenta Personal&rdquo; o &ldquo;Cuentas
                    Personales&rdquo;) de sus fondos corporativos.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Conforme a la normativa del Banco Central de la Rep&uacute;blica Argentina, los fondos depositados
                    en la Cuenta Personal del Usuario no generan intereses, pudiendo el mismo disponer libremente de
                    ellos para realizar operaciones.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario entiende que el retiro y/o la transferencia de los fondos se encuentra supeditada a la
                    disponibilidad de los mismos en su Cuenta Personal. El Usuario podr&aacute; retirar todo o parte del
                    saldo disponible en su Cuenta Personal, sin que aquello implique necesariamente el cierre de su
                    Cuenta Personal y/o el cese de los servicios provistos por Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.11. Responsabilidad por los fondos</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay mantendr&aacute; los fondos de los usuarios en cuentas dentro de entidades financieras
                    pertenecientes al sistema financiero argentino.&nbsp;
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Los Usuarios eximen de responsabilidad a Arpay por situaciones que pudieran afectar a los fondos
                    depositados en sus Cuentas Personales. Tal afectaci&oacute;n podr&aacute; ocurrir, sin
                    exclusi&oacute;n de otros motivos no enunciados, ante: a) la insolvencia de dichas entidades
                    financieras, b) motivos de &iacute;ndole pol&iacute;tico y/o econ&oacute;mico a nivel nacional, c)
                    circunstancias ajenas a Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    De darse alguno de estos supuestos, ninguna responsabilidad puede ser atribu&iacute;da a Arpay por
                    parte de los Usuarios. Tampoco dispondr&aacute;n &eacute;stos de la facultad de exigir reintegros,
                    pagos por lucro cesante, p&eacute;rdida de chance y/o cualquier otro da&ntilde;o y perjuicio que
                    pudiera haber sufrido como consecuencia de los supuestos antes mencionados.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>3.12. Naturaleza del servicio</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario comprende y acepta que Arpay no es una entidad financiera y que los servicios de
                    gesti&oacute;n de pagos prestados por medio de la Plataforma no constituyen ning&uacute;n tipo de
                    actividad cambiaria, bancaria, financiera o de inversi&oacute;n.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>IV . TERMINACI&Oacute;N</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    4.1 Oportunidad de la terminaci&oacute;n
                    <br />
                    <br />
                    Arpay o el Usuario podr&aacute;n en cualquier oportunidad durante la vigencia del presente contrato
                    finalizarlo sin causa alguna, esto implicar&aacute; el cierre de la cuenta del Usuario, pero no
                    cancelar&aacute; con las solicitudes ya autorizadas por el Usuario al momento de la
                    notificaci&oacute;n de la terminaci&oacute;n del contrato. Para poder llevar a cabo el cierre de la
                    cuenta, la parte que la solicita no debe adeudar a la otra ni a terceros involucrados el
                    cumplimiento de una obligaci&oacute;n.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>4.2 Fallecimiento del titular de la Cuenta Arpay</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Ante el fallecimiento o incapacidad sobreviniente del titular de la Cuenta Arpay, los herederos o
                    representantes legales deber&aacute;n presentar la informaci&oacute;n solicitada por Arpay para
                    poder brindarles acceso a la cuenta. Hasta que la documentaci&oacute;n solicitada no sea
                    proporcionada Arpay podr&aacute; suspender el acceso a la cuenta hasta que se brinde la
                    documentaci&oacute;n solicitada. Una vez presentada la documentaci&oacute;n se les brindar&aacute; a
                    los sujetos autorizados acceso a la cuenta &uacute;nicamente para retirar los fondos y luego
                    proceder con el cierre definitivo de la cuenta.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>4.3 Informaci&oacute;n del Usuario</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Los servicios prestados por Arpay por medio de la Plataforma tienen una duraci&oacute;n
                    indeterminada. Sin embargo, el Usuario acepta que Arpay puede terminar, suspender o interrumpir la
                    prestaci&oacute;n de los servicios en forma unilateral, discrecional y sin expresi&oacute;n de
                    causa.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    En caso de que el Usuario incurra en cualquier tipo de incumplimiento respecto a las condiciones de
                    uso y obligaciones que surgen de estos T&eacute;rminos, Arpay podr&aacute; dar por terminada la
                    prestaci&oacute;n de los servicios sin ning&uacute;n tipo de previo aviso.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>V.- CASO FORTUITO</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay no ser&aacute; responsable por cualquier incumplimiento, p&eacute;rdida, retraso,
                    suspensi&oacute;n, falla o imposibilidad en la prestaci&oacute;n de los servicios y/o en el
                    cumplimiento de estos T&eacute;rminos con motivo cualquier evento que pudiera ser considerado como
                    producto de caso fortuito, fuerza mayor o el hecho de un tercero por quien no debe responder.
                    Deber&aacute; entenderse que se trata de caso fortuito, fuerza mayor o hechos de un tercero, sin
                    limitaci&oacute;n ni exclusi&oacute;n de otros, a los siguientes: restricciones o regulaciones
                    gubernamentales, embargos, ataques inform&aacute;ticos o cibern&eacute;ticos de cualquier tipo,
                    robos, hurtos, cualquier tipo de defraudaci&oacute;n, actos de terrorismo, guerras, disturbios,
                    huelgas, disputas laborales o industriales de cualquier tipo, incendios, explosiones, terremotos,
                    inundaciones, accidentes, actos de sabotaje, perturbaciones ambientales, condiciones
                    clim&aacute;ticas inusualmente severas, falla de telecomunicaciones, falla de energ&iacute;a,
                    interrupciones de la conexi&oacute;n a Internet, incumplimientos en los proveedores de servicios
                    vinculados, o cualquier otra causa que no hubiera podido ser prevista o que, habi&eacute;ndolo sido,
                    no hubiera podido ser evitada. El Usuario acepta que Arpay no garantiza el acceso continuado e
                    ininterrumpido a sus servicios y Plataforma, la cu&aacute;l puede eventualmente no estar disponible
                    por los motivos antes indicados, o por cualquier otra circunstancia.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>VI.- LIMITACI&Oacute;N DE GARANT&Iacute;A</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Los servicios prestados por Arpay por medio de la plataforma no cuentan con ning&uacute;n tipo de
                    garant&iacute;a de comercializaci&oacute;n ni de idoneidad para el cumplimiento de alg&uacute;n
                    prop&oacute;sito en particular. Arpay no garantiza al Usuario que los servicios sean prestados o se
                    encuentren disponibles de forma permanente e ininterrumpida, segura o sin errores. Arpay no ofrece
                    ning&uacute;n tipo de garant&iacute;a respecto a la calidad, precisi&oacute;n, puntualidad,
                    veracidad, integridad o confiabilidad de los servicios prestados o de la informaci&oacute;n obrante
                    en la Plataforma.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>VII.- INDEMNIDAD</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario mantendr&aacute; indemne a Arpay, sus accionistas, empleados, agentes, directivos,
                    titulares y a cualquiera de sus miembros respecto de cualquier reclamo o procedimiento
                    administrativo, extrajudicial o judicial de cualquier &iacute;ndole y ser&aacute; responsable por
                    los da&ntilde;os, p&eacute;rdidas, costos y gastos, los cuales, entre otros, incluyen los honorarios
                    de abogados, peritos y tasas judiciales, que se encuentren relacionados de cualquier forma con su
                    acceso a la Plataforma, la utilizaci&oacute;n de los servicios, la actividad desarrollada por el
                    Usuario respecto a Arpay y/o la violaci&oacute;n de cualquiera de las disposiciones contenidas en
                    estos T&eacute;rminos.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>VIII.- SITIOS WEB O RECURSOS DE TERCEROS</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El Usuario comprende y acepta que la Plataforma podr&aacute; contener enlaces a sitios web o
                    recursos de terceros, que son proporcionados &uacute;nicamente para su comodidad o conveniencia. En
                    consecuencia, Arpay no es responsable en forma alguna del contenido, los servicios, los productos,
                    las ofertas y/o cualquier otro tipo de informaci&oacute;n que pudieran contener dichos sitios web o
                    recursos de terceros.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>IX.- PROPIEDAD INTELECTUAL</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay y/o sus sociedades controlantes, controladas, filiales o subsidiarias, es la propietaria de
                    todos los derechos intelectuales, de autor&iacute;a e industriales respecto a la plataforma, el
                    dominio, el software, el hardware, los logos, los logotipos, los emblemas, los dise&ntilde;os, la
                    informaci&oacute;n y los contenidos. El Usuario no posee ning&uacute;n derecho respecto a aquellos y
                    el uso indebido y la reproducci&oacute;n total o parcial de dichos contenidos quedan prohibidos,
                    salvo autorizaci&oacute;n fehaciente de Arpay.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>X.- POL&Iacute;TICA DE PRIVACIDAD</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Al acceder a la plataforma y/o utilizar los servicios que la Arpay presta por medio de aquella, el
                    Usuario acepta haber le&iacute;do y encontrarse sujeto a las pol&iacute;ticas de privacidad insertas
                    dentro de la Plataforma. Adicionalmente, el Usuario autoriza y presta consentimiento para que Arpay
                    eventualmente proporcione datos personales de su pertenencia a determinados proveedores de servicios
                    de valor agregado que se integren dentro de la Plataforma, o mediante links a otros sitios de
                    Internet.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>XII.- INFORMACI&Oacute;N DE CONTACTO Y SOPORTE</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    En caso de querer realizar alguna pregunta o evacuar una consulta, el Usuario podr&aacute;
                    comunicarse con Arpay escribiendo un correo electr&oacute;nico a contacto@fcmps.com.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>XIII.- DOMICILIOS Y NOTIFICACIONES</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay constituye su domicilio a los efectos del presente en la calle Mir&oacute; 460, piso 1&deg;,
                    departamento &ldquo;A&rdquo; de la Ciudad Aut&oacute;noma de Buenos Aires, Rep&uacute;blica
                    Argentina.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    El domicilio del Usuario ser&aacute; aqu&eacute;l con el cu&aacute;l se registr&oacute; durante el
                    procedimiento de validaci&oacute;n de su identidad establecido en la Plataforma.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Arpay y el Usuario aceptan que ser&aacute;n v&aacute;lidas todas las notificaciones cursadas a
                    dichos domicilios.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>XIV.- LEY APLICABLE</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Estos T&eacute;rminos se rigen exclusivamente por las leyes vigentes en la Rep&uacute;blica
                    Argentina.
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>XV.- JURISDICCI&Oacute;N</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Cualquier controversia o procedimiento administrativo, extrajudicial o judicial derivado de estos
                    T&eacute;rminos, la Plataforma y los servicios prestados por Arpay deber&aacute; someterse
                    exclusivamente a la jurisdicci&oacute;n de los juzgados nacionales con competencia en materia
                    comercial con sede en la ciudad de Buenos Aires.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Body;
