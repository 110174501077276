import { ReactComponent as BankingApp } from 'Assets/Img/BankingApp.svg';

const Banking = () => (
  <div id="Banking" className="bg-gray-50 bg-opacity-50">
    <div className="flex flex-col md:flex-row items-center max-w-7xl min-h-[700px] mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex ">
      <div className="max-w-xl flex w-full justify-center flex-1">
        <BankingApp />
      </div>
      <div className="mt-10 max-w-md flex-1">
        <h2 className="text-3xl font-bold text-gray-900 sm:tracking-tight ">Capacidades de CVU en tu poducto</h2>
        <p className="mt-5 text-lg text-gray-400">
          La creación de una Clave Virtual Uniforme le permitirá la identificación y trazabilidad de transferencias de
          fondos entre cuentas de forma instantánea.
        </p>
      </div>
    </div>
  </div>
);
export { Banking };
