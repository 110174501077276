/* eslint-disable */

import { useEffect } from "react"
import { DOCK_LINK_TITLES } from "utils/constants/DocInfo.constants"
import Banking from "./Banking"
import Partner from "./Partner"
import WebhookNotification from "./WebhookNotification"

const DocInfoTypes = {
    [DOCK_LINK_TITLES.BANKING]: <Banking />,
    [DOCK_LINK_TITLES.PARTNER]: <Partner />,
    [DOCK_LINK_TITLES.WEBHOOK]: <WebhookNotification />,

}
const DocInfo = ({ type, subtype }) => {
    useEffect(() => {
        if (subtype) {
            const element = document.getElementById(subtype);
            element.scrollIntoView({ behavior: 'smooth' }, true)
        }
    }, [subtype])
    return (
        <>
            {type && DocInfoTypes[type]}
        </>
    )
}

export default DocInfo