import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './Components/Home/Home';
import Legals from './Components/Legals/Legals';
import ApiDocs from 'Components/ApiDocs/ApiDocs';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/legals" component={Legals} />
          <Route path="/api-docs" component={ApiDocs} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
