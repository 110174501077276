import { ReactComponent as QrCardPayment } from 'Assets/Img/QrCardPayment.svg';

const QrSection = () => (
  <div id="QrSection" className="bg-white">
    <div className="flex flex-col md:flex-row items-center max-w-7xl min-h-[700px] mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex justify-center">
      <div className="mt-10 max-w-md flex-1">
        <h2 className="text-3xl font-bold text-gray-900 sm:tracking-tight ">
          Integración de pagos QR para tu negocio, sitio web o aplicación mobile
        </h2>
        <p className="mt-5 text-lg text-gray-400">
          Ofrecé a tu cliente la posibilidad de pagar desde su celular en cualquier momento y lugar.
        </p>
      </div>
      <div className="max-w-xl flex w-full justify-center flex-1">
        <QrCardPayment />
      </div>
    </div>
  </div>
);
export { QrSection };
