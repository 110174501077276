/* eslint-disable */

import GreyCard from "Components/shared/GreyCard"
import { dockLinksIds } from "utils/constants/DocInfo.constants"

const Partner = () => {
    return (
        <div>
            <h2 className="text-3xl font-bold  text-gray-900 mb-4">
                Partner
            </h2>
            <div className="px-6">
                <li id={dockLinksIds.Partner_Registration} className="py-4 font-bold mb-2 underline">Registration</li>
                <div className="px-6 mb-4">
                    <p className="mb-2">The client registration process to ArPay API will be executed by an ArPay operator, who before hand will need to receive from the new client the <i className="underline">URL for webhook’s notifications, an email, and a hash.</i></p>
                    <p className="mb-2">Once the client has been registered, the client will receive an email from the email address <span className="text-settle-primary-500">registration@settlecore.com</span> with generic credentials to login into the API (POST /partners/login) and therefore generate the session token.</p>
                    <p className="mb-4">As a last step of the onboarding process for a new client, that token will be used to <i className="font-bold">update the generic credentials as soon as possible</i> by consuming the PATCH/partners/register and PATCH /partners/configs/webhooks endpoints explained below:</p>
                    <p className="mb-2 underline">Minimum validations:</p>
                    <p><b>- email:</b> valid email</p>
                    <p><b>- partnerUser:</b> username</p>
                    <p><b>- password:</b> 10 characters or more, at least 1 capital letter, at least 1 lowercase letter & at least 1 special character (not alphabetic nor numeric)</p>
                    <p><b>- url:</b> for the webhook notification's delivery</p>
                    <p><b>- hash:</b> in base64 for the webhook notification's delivery</p>
                </div>

                <li id={dockLinksIds.Partner_Auth} className="py-4 font-bold mb-2 underline">Auth</li>
                <div className="px-6 mb-4">

                    <p className="px-4 mb-2">- POST /partners/login</p>

                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"user": partner user registered,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"password": partner password registered</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"token": "eyJhbGciOiJIUzI1N…"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="mt-5 mb-2">After login with the final credentials, the <i className="font-bold">“Authorization”</i> header must be passed as follows for all request that require partnerSession:</p>
                    <GreyCard>
                        <i className="px-4">Authorization: Bearer eyJhbGciOiJIUzI1N…</i>
                    </GreyCard>

                    <p className="px-4 mb-2">- PATCH /partners/register</p>
                    <p className="px-4 mb-2">Endpoint to update the following parameters: <i>email, user, password</i></p>
                    <p className="px-4 underline">Conditions:</p>
                    <p className="px-4"><b>-</b> Requires partnerSession</p>
                    <p className="px-4"><b>-</b> At least one <i>“new”</i> param is expected (newEmail, newPartnerUser, newPassword)</p>
                    <p className="px-4 mb-2"><b>-</b> Email & password must have valid formats.</p>
                    <p className="px-4 mb-2"><b>Disclaimer:</b> Notice that right after making any modification in the credentials, a new session token will be created.</p>

                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"password": partner password shared by us,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"newEmail": new partner email to be configured,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"newPartnerUser": new partner user to be configured,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"newPassword": new partner user to be configured,</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"status": "approved"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                </div >

                <li id={dockLinksIds.Partner_Api_key} className="py-4 font-bold mb-2 underline">Api-Key (Requires partnerSession)</li>
                <div className="px-6 mb-4">

                    <p className="px-4 mb-2">- GET /partners/api-key</p>
                    <p className="px-4 mb-2">Endpoint to retrieve all the active api keys for a given partner</p>

                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>-</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiName": "api2",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiKey": "owemfpwnfwifvnerono=="</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- POST /partners/api-key</p>
                    <p className="px-4 mb-2">Endpoint to create a pair api key/secret for a given partner</p>
                    <p className="px-4 mb-2"><b>Disclaimer:</b> in case the client is willing to obtain a new API key and API token, when they use an existing apiName as a request parameter, then the old API key and API token with the same name will be replaced by the new one.</p>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiName": The wanted api’s name, for identification</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiKey": "kvnrivneovnoe==",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiSecret": "34423eeb7JVJOERVENveinrvoe…",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiName": "ApiName"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- DELETE /partners/api-key</p>
                    <p className="px-4 mb-2">Endpoint to delete a given api key</p>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"apiName": The api’s name</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <div className="px-2 mx-4 bg-gray-50 rounded-md py-3">
                        <p className="px-4 mb-2"><i>200</i></p>
                    </div>

                    <p className="px-4 mb-2">After the creation of an Api-Key, two headers will have to be added to all request</p>
                    <p className="px-4 mb-2">which require <i className="font-bold">partnerKey</i>, this are obtained as a response in the api-key creation:</p>
                    <GreyCard>
                        <p className="px-4 mx-4 mb-2"><i><b>x-api-key:</b> vsnjnsov+qQ==</i></p>
                        <p className="px-4 mx-4 mb-2"><i><b>x-api-secret:</b> vwwveihh387hh83bc8gef…</i></p>
                    </GreyCard>
                </div>

                {/*  */}
                <li id={dockLinksIds.Partner_Webhook} className="py-4 font-bold mb-2 underline">Webhook (Requires partnerSession)</li>
                <div className="px-6 mb-4">
                    <p className="px-4 mb-2">- GET /partners/configs/webhooks</p>
                    <p className="px-4 mb-2">Endpoint to query about ALL the current active webhook subscriptions</p>

                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>-</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-2 mb-2"><i>{'['}</i></p>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": "Default",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": "www.url.com"</i></p>
                        <p className="px-4 mb-2"><i>{'},'}</i></p>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": "Type2",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": "www.url2.com"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                        <p className="px-2 mb-2"><i>{']'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- GET /partners/configs/webhooks/{'{eventType}'}</p>
                    <p className="px-4 mb-2">Endpoint to query about a specific active webhook evenType subscription</p>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mx-4 mb-2"><i>eventType: The specific event type you want information about.</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": "Type2",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": "www.url2.com"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- POST /partners/configs/webhooks</p>
                    <p className="px-4 mb-2">Endpoint to subscribe a given client to the webhooks events and define the URL where to make the POST, and the hash to authenticate the communication.</p>
                    <p className="px-4 mb-2 underline">Enum evenType:</p>
                    <div className="px-4 mb-2">
                        <li className="px-2"><i className="font-bold">FiatCredit:</i> notify about a cash in tx in a given account</li>
                        <li className="px-2"><i className="font-bold">FiatDebit:</i> notify about a cash out tx in a given account</li>
                        <li className="px-2"><i className="font-bold">DeliveryUpdate:</i> notify about a fiat credit tx in a given account</li>
                        <li className="px-2"><i className="font-bold">OnboardingUpdate:</i> notify about an onboarding status update</li>
                        <li className="px-2"><i className="font-bold">Default:</i> subscribe to all notifications by default</li>
                    </div>

                    <p className="px-4 mb-2 underline">Conditions:</p>
                    <li className="px-2">- Valid event type.</li>
                    <li className="px-2">- Event type not registered already.</li>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": The event type being registered,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": URL that will receive the specific notifications,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"hash": Base64 hash to validate notifications of this type</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": "Type2",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": "www.url2.com"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- PATCH /partners/configs/webhooks</p>
                    <p className="px-4 mb-2">Endpoint that will be used to modify URL or hash for webhook notifications.</p>
                    <p className="px-4 mb-2 underline">Have the following into consideration:</p>
                    <li className="px-2"><i>- Hash:</i> needs to be base64 encoded</li>
                    <li className="px-2">Notice that if there are notifications pending that were created right before making some URL/hash change, those notifications will be delivered to the “old“ parameters.</li>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": The event type being modified,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": new URL,</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"hash": new Hash</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"eventType": "Type2",</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"url": "www.newurl2.com"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>

                    <p className="px-4 mb-2 mt-2">- DELETE /partners/configs/webhooks/{'{eventType}'}</p>
                    <p className="px-4 mb-2">Endpoint that allow a client to delete a given webhook subscription.</p>
                    <p className="px-4 mb-2 underline"><b>Disclaimer:</b> Notice that it won’t be possible to delete a eventType = 'Default'</p>
                    <p className="px-4 mb-2">REQ:</p>
                    <GreyCard>
                        <p className="px-4 mx-4 mb-2"><i>eventType: The specific event type you want to delete.</i></p>
                    </GreyCard>
                    <p className="px-4 mb-2 mt-5">RES:</p>
                    <GreyCard>
                        <p className="px-4 mb-2"><i>{'{'}</i></p>
                        <p className="px-4 mx-4 mb-2"><i>"details": "Webhook FiatCredit has been deleted successfully"</i></p>
                        <p className="px-4 mb-2"><i>{'}'}</i></p>
                    </GreyCard>
                </div>
            </div >
        </div >
    )
}

export default Partner