import LogoBCRA from 'Assets/Img/Logo_bcra.png';
import LogoBCRA2x from 'Assets/Img/Logo_bcra@2x.png';
import LogoBCRA3x from 'Assets/Img/Logo_bcra@3x.png';

const Hero = () => (
  <div id="Hero" className="sm:text-center lg:text-left ">
    <h1 className="text-5xl tracking-tight font-extrabold text-gray-900 ">
      <span className="">Servicio de procesamiento de </span>
      <span className="text-settle-primary-500 xl:inline">pagos digitales</span> en Argentina
    </h1>
    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
      <div className="mt-3 sm:mt-0 ">
        <a
          href="#Form"
          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-settle-primary-500 hover:bg-settle-primary-600  md:py-4 md:text-lg md:px-10"
        >
          Solicitar API
        </a>
      </div>
    </div>
    <div className="flex flex-col md:flex-row mt-20">
      <a
        href="http://arpay.com.ar/files/bcra/30716897423Certificado.pdf"
        target="_blank"
        rel="noreferrer"
        className="flex justify-center mb-4 sm:mb-0 md:justify-end"
      >
        <img
          className="h-12 sm:h-14 mr-1 sm:mr-4"
          src={LogoBCRA}
          srcSet={`${LogoBCRA} 1x, ${LogoBCRA2x} 2x, ${LogoBCRA3x} 3x`}
        />
      </a>
      <p className="self-center font-medium text-gray-400 sm:max-w-xl sm:mx-auto md:mx-0">
        <span className="self-center text-settle-primary-500">arPay</span> es un proveedor de Servicios de Pago
        autorizado por el BCRA.
      </p>
    </div>
  </div>
);

export { Hero };
