import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Switch } from '@headlessui/react';

import { ValidationMessage } from './ValidationMessage';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Form = () => {
  const [agreed, setAgreed] = useState(false);
  const [submited, setSubmited] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data, e) => {
    setSubmited(true);
    e.target.reset();
  };
  return (
    <div id="Form" className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width={404}
          height={404}
          fill="none"
          viewBox="0 0 404 404"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={404} fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)" />
        </svg>
        <div className="text-center">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            ¿Con qué podemos ayudarte?
          </h2>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Si queres saber mas sobre arPay no dudes en contactarnos.
          </p>
        </div>
        <div className="mt-12">
          <ValidationMessage
            hasSuccess={submited}
            onClick={() => {
              setSubmited(!submited);
            }}
          />
          <form
            target="_blank"
            action="https://formsubmit.co/ezequiel@settlenetwork.com"
            method="POST"
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
          >
            {/* Nombre */}
            <div>
              <label className="block text-left text-sm font-regular text-gray-400">Nombre</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className={`appearance-none block w-full px-3 border ${
                  errors.firstName
                    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 : focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : 'border-settle-gray-200 placeholder-settle-gray-300 focus:outline-none focus:ring-settle-blue-500 focus:border-settle-blue-500'
                }  text-sm rounded-md`}
                {...register('firstName', {
                  required: true,
                })}
              />
              {errors.firstName?.type === 'required' && (
                <p className="text-sm text-red-600" id="email-error">
                  Campo requerido
                </p>
              )}
            </div>
            {/* Apellido */}
            <div>
              <label className="block text-left text-sm font-regular text-gray-400">Apellido</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className={`appearance-none block w-full px-3 border ${
                  errors.lastName
                    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 : focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : 'border-settle-gray-200 placeholder-settle-gray-300 focus:outline-none focus:ring-settle-blue-500 focus:border-settle-blue-500'
                }  text-sm rounded-md`}
                {...register('lastName', {
                  required: true,
                })}
              />
              {errors.lastName?.type === 'required' && (
                <p className="text-sm text-red-600" id="email-error">
                  Campo requerido
                </p>
              )}
            </div>
            {/* Empresa */}
            <div className="sm:col-span-2">
              <label className="block text-left text-sm font-regular text-gray-400">Empresa</label>
              <input
                type="text"
                name="company"
                id="company"
                className={`appearance-none block w-full px-3 border ${
                  errors.company
                    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 : focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : 'border-settle-gray-200 placeholder-settle-gray-300 focus:outline-none focus:ring-settle-blue-500 focus:border-settle-blue-500'
                }  text-sm rounded-md`}
                {...register('company', {
                  required: true,
                })}
              />
              {errors.company?.type === 'required' && (
                <p className="text-sm text-red-600" id="email-error">
                  Campo requerido
                </p>
              )}
            </div>
            {/* Mail */}
            <div className="sm:col-span-2">
              <label className="block text-left text-sm font-regular text-gray-400">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                className={`appearance-none block w-full px-3 border ${
                  errors.email
                    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 : focus:outline-none focus:ring-red-500 focus:border-red-500'
                    : 'border-settle-gray-200 placeholder-settle-gray-300 focus:outline-none focus:ring-settle-blue-500 focus:border-settle-blue-500'
                }  text-sm rounded-md`}
                {...register('email', {
                  required: 'Enter your e-mail',
                  pattern: {
                    required: true,
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  },
                })}
              />

              {errors.email?.type === 'required' && (
                <p className="text-sm text-red-600" id="email-error">
                  Ingresá un mail válido
                </p>
              )}
            </div>
            {/* Messagge */}
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-left text-sm font-regular text-gray-400">
                Mensaje
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className={`appearance-none block w-full px-3 border ${
                    errors.message
                      ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 : focus:outline-none focus:ring-red-500 focus:border-red-500'
                      : 'border-settle-gray-200 placeholder-settle-gray-300 focus:outline-none focus:ring-settle-blue-500 focus:border-settle-blue-500'
                  }  text-sm rounded-md`}
                  {...register('message', {
                    required: 'Enter your e-mail',
                    pattern: {
                      required: true,
                    },
                  })}
                />
                {errors.message?.type === 'required' && (
                  <p className="text-sm text-red-600" id="email-error">
                    Campo requerido obligatorio
                  </p>
                )}
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <Switch
                    checked={agreed}
                    onChange={setAgreed}
                    className={classNames(
                      agreed ? 'bg-settle-primary-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-settle-primary-500'
                    )}
                  >
                    <span className="sr-only">Aceto las politicas</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        agreed ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    Acepto las <a className="font-medium text-gray-700 ">Política de Privacidad</a> y{' '}
                    <a className="font-medium text-gray-700 ">Política de Cookies</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <button
                type="submit"
                className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-settle-primary-600 hover:bg-settle-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-settle-primary-500"
                onClick={() => {
                  handleSubmit;
                }}
              >
                Hablemos
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { Form };
