import { HashLink as Link } from 'react-router-hash-link';

import LogoBCRA from 'Assets/Img/Logo_bcra.png';
import LogoBCRA2x from 'Assets/Img/Logo_bcra@2x.png';
import LogoBCRA3x from 'Assets/Img/Logo_bcra@3x.png';
import LogoAAIP from 'Assets/Img/Logo_aaip.png';
import LogoUIF from 'Assets/Img/Logo_uif.png';
import LogoUIF2x from 'Assets/Img/Logo_uif@2x.png';
import LogoUIF3x from 'Assets/Img/Logo_uif@3x.png';

const navigation = {
  solutions: [
    { name: 'Banking', hash: '/#Banking' },
    { name: 'Pagar con Qr', hash: '/#QrSection' },
    { name: 'Integración vía API', hash: '/#ApiSection' },
  ],
};

const Footer = () => (
  <footer className="bg-gray-500">
    <div className="max-w-7xl mx-auto py-8 px-6 sm:px-8 lg:py-16 lg:px-12">
      <div className="xl:grid xl:grid-cols-6 xl:gap-4">
        <div>
          <h3 className="text-sm font-semibold text-white tracking-wider uppercase mt-10 sm:mt-0">Soluciones</h3>
          <ul role="list" className="mt-4 space-y-4">
            {navigation.solutions.map((item) => (
              <li key={item.name}>
                <Link className="text-base text-white hover:text-gray-200" to={item.hash}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-10 lg:mt-0">
          <h3 className="text-sm font-semibold text-white tracking-wider uppercase mt-10 sm:mt-0">Legales</h3>
          <ul role="list" className="mt-4 space-y-4">
            <Link className="text-base text-white hover:text-gray-200" to="/legals">
              Términos
            </Link>
          </ul>
        </div>
        <div>
          <div className="flex flex-row justify-start md:justify-start lg:justify-around mt-10 sm:mt-8 md:mt-6 lg:mt-0">
            <a
              href="https://www.argentina.gob.ar/aaip/datospersonales/reclama/30716897423--RL-2021-65143177-APN-DNPDP#AAIP"
              className="mr-8 sm:mr-4"
            >
              <img src={LogoAAIP} className="h-[4rem] sm:h-[5rem]" />
            </a>
            <a href="https://arpay.com.ar/files/bcra/30716897423Certificado.pdf" target="_blank" rel="noreferrer">
              <img
                className="h-[4rem] sm:h-[5rem]"
                src={LogoBCRA}
                srcSet={`${LogoBCRA} 1x, ${LogoBCRA2x} 2x, ${LogoBCRA3x} 3x`}
              />
            </a>
          </div>
        </div>
        <div className="flex flex-row justify-start md:justify-start md:col-span-2 lg:justify-around mt-10 sm:mt-8 md:mt-6 lg:mt-0">
          <p className="text-white text-base flex flex-col col-span-2 mt-6 sm:mt-4 md:mt-2 lg:mt-0">
            FCM PAYMENT SOLUTIONS SAS(ARPAY) es un Proveedor de Servicios de Pago autorizado por el BCRA. No está
            autorizado para operar como entidad financiera.&nbsp;
            <Link
              className="text-base font-bold underline underline-offset-1 text-white hover:text-gray-200"
              to="/legals"
            >
              Más info acá
            </Link>
          </p>
        </div>
        <div className="flex flex-row justify-start md:justify-start lg:justify-around mt-10 sm:mt-8 md:mt-6 lg:mt-0">
          <a
            href="https://arpay.com.ar/files/uif/FCMPS_CONSTANCIA_DE_REGISTRO_UIF.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="h-[4rem] sm:h-[5rem]"
              src={LogoUIF}
              srcSet={`${LogoUIF} 1x, ${LogoUIF2x} 2x, ${LogoUIF3x} 3x`}
            />
          </a>
        </div>
      </div>
      <div className="mt-12 border-t border-gray-200 pt-8">
        <p className="text-center text-base text-white">&copy; Todos los derechos reservados a arPay</p>
      </div>
    </div>
  </footer>
);

export { Footer };
